import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthServiceMock extends AuthService {
  override runInitialLoginSequence(): Promise<void> {
    this.isAuthenticatedSubject$.next(true);
    this.isDoneLoadingSubject$.next(true);
    return Promise.resolve();
  }
}
