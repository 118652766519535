import { AuthServiceMock } from './auth-service.mock';
import { isMockAuth } from '@camino-solutions/core/environment';
import { Provider } from '@angular/core';
import { AuthService } from './auth.service';

export const AuthServiceProvider: Provider = isMockAuth()
  ? {
      provide: AuthService,
      useClass: AuthServiceMock,
    }
  : {
      provide: AuthService,
    };
