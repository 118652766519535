import { produce } from 'immer';
import { inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { APP_STATE_TOKEN } from './app-state.token';
import { AutoLoggedInAction } from './action/auth-logged-in.action';
import { RemoveSplashAction } from './action/remove-splash.action';
import type { AppMainMenuStateModel, AppStateModel } from './app-state.model';
import { DOCUMENT } from '@angular/common';
import { IdleStartAction } from './action/idle-start.action';
import { IdleEndAction } from './action/idle-end.action';
import { IdleTimeoutWarningAction } from './action/idle-timeout-warning.action';
import { ChangeThemeAction } from './action/change-theme.action';
import { MenuAnchorToggleAction } from './action/menu-anchor-toggle.action';

@State<AppStateModel>({
  name: APP_STATE_TOKEN,
  defaults: produce(
    {} as AppStateModel,
    () =>
      ({
        inited: false,
        isLoggedIn: false,
        showSplash: false,
        loading: true,
        idle: false,
        theme: 'light',
        menu: {
          anchored: false,
        },
      }) satisfies AppStateModel,
  ),
})
@Injectable()
export class AppState implements NgxsOnInit {
  readonly #document = inject(DOCUMENT);
  readonly #window = this.#document.defaultView;

  @Selector([AppState])
  static inited({ inited }: AppStateModel): AppStateModel['inited'] {
    return inited;
  }

  @Selector([AppState])
  static showSplash({ showSplash }: AppStateModel): AppStateModel['showSplash'] {
    return showSplash;
  }

  @Selector([AppState])
  static idle({ idle }: AppStateModel): AppStateModel['idle'] {
    return idle;
  }

  @Selector([AppState])
  static idleTimeoutWarningSeconds({
    idleTimeoutWarningSeconds,
  }: AppStateModel): AppStateModel['idleTimeoutWarningSeconds'] {
    return idleTimeoutWarningSeconds;
  }

  @Selector([AppState])
  static theme({ theme }: AppStateModel): AppStateModel['theme'] {
    return theme;
  }

  @Selector([AppState])
  static menu({ menu }: AppStateModel): AppStateModel['menu'] {
    return menu;
  }

  @Selector([AppState.menu])
  static menuAnchored({ anchored }: AppMainMenuStateModel): AppMainMenuStateModel['anchored'] {
    return anchored;
  }

  ngxsOnInit(ctx: StateContext<AppStateModel>) {
    const theme = ctx.getState().theme;
    if (theme !== 'light') {
      if (theme === 'browser') {
        if (
          this.#window?.matchMedia &&
          this.#window?.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          ctx.dispatch(new ChangeThemeAction('dark'));
        }
      } else {
        this.#loadTheme('light', theme);
      }
    } else {
      this.#document.body.classList.add(`theme-${theme}`);
    }
  }

  @Action(ChangeThemeAction)
  changeTheme({ getState, setState }: StateContext<AppStateModel>, { theme }: ChangeThemeAction) {
    const previousTheme = getState().theme;

    setState(
      produce(draft => {
        draft.theme = theme;
      }),
    );
    this.#loadTheme(previousTheme, theme);
  }

  @Action(AutoLoggedInAction)
  async autoLoggedIn({ setState, dispatch }: StateContext<AppStateModel>) {
    setState(
      produce(draft => {
        draft.isLoggedIn = true;
        draft.inited = true;
      }),
    );
  }

  @Action(RemoveSplashAction)
  removeSplash({ setState }: StateContext<AppStateModel>) {
    setState(
      produce(draft => {
        draft.showSplash = false;
        draft.loading = false;
      }),
    );
  }

  @Action(IdleStartAction)
  idleStart({ setState }: StateContext<AppStateModel>) {
    setState(
      produce(draft => {
        draft.idle = true;
      }),
    );
  }

  @Action(IdleEndAction)
  idleEnd({ setState }: StateContext<AppStateModel>) {
    setState(
      produce(draft => {
        draft.idle = false;
        draft.idleTimeoutWarningSeconds = undefined;
      }),
    );
  }

  @Action(IdleTimeoutWarningAction)
  idleTimeoutWarning(
    { setState }: StateContext<AppStateModel>,
    { seconds }: IdleTimeoutWarningAction,
  ) {
    setState(
      produce(draft => {
        draft.idleTimeoutWarningSeconds = seconds;
      }),
    );
  }

  @Action(MenuAnchorToggleAction)
  menuAnchorToggle({ setState }: StateContext<AppStateModel>) {
    setState(
      produce(draft => {
        draft.menu.anchored = !draft.menu.anchored;
      }),
    );
  }

  #replaceThemeLink(href: string, themeLink: HTMLLinkElement) {
    const id = 'theme-link';
    const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);
    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
    });
  }

  #loadTheme(previousTheme: AppStateModel['theme'], theme: AppStateModel['theme']) {
    const themeLink = this.#document.getElementById('theme-link') as HTMLLinkElement;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const themeLinkHref = themeLink.getAttribute('href')!;
    const newHref = themeLinkHref
      .split('/')
      .map(el => (el === `theme-${previousTheme}.css` ? (el = `theme-${theme}.css`) : el))
      .join('/');
    this.#replaceThemeLink(newHref, themeLink);
    this.#document.body.classList.remove(`theme-${previousTheme}`);
    this.#document.body.classList.add(`theme-${theme}`);
  }
}
